<template>
  <div>
    <img
      v-for="i of 5"
      :key="i"
      class="stars"
      :src="i - 1 < star ? '/img/cmn/img-star-active@2x.png' : '/img/cmn/img-star@2x.png'"
      :width="width"
      :height="height"
      alt
      @click="changeStar(i)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 3
    },
    disabled: {
      type: Boolean,
      defaullt: false,
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    }
  },
  computed: {
    star: {
      get() {
        return Math.round(this.value)
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    changeStar(i) {
      if (!this.disabled) {
        this.star = i
      }
    }
  }
};
</script>