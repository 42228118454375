<template>
  <div class="search_result">
    <a
      v-for="c in contents"
      :key="c.id"
      class="c_search_item"
      @click="clickContents(c)"
    >
      <figure class="c_search_item_img">
        <img
          v-if="c.image_path"
          :src="`https://${jc3LearningDomain}${c.image_path}`"
          alt
          width="65"
          height="65"
        />
        <img
          v-else
          src="http://placehold.jp/cfcfcf/4a4a4a/65x65.png?text=NO%0AIMAGE"
          alt
          width="65"
          height="65"
        />
      </figure>
      <div class="c_search_item_cnt">
        <p class="c_search_item_ttl">
          <span>{{ c.category_name }}</span>
          <em>{{ c.name }}</em>
        </p>
        <p class="c_tool_item_author">
          <em>{{ c.author_name }}/{{ c.author_belonging }}</em>
        </p>
        <p class="c_search_item_disc">{{ c.description }}</p>
        <div class="c_tool_item_eval">
          <div
            class="c_tool_item_eval mr-1"
            v-if="c.type === 8 || c.type === 16"
          >
            <div class="c_tool_item_eval_star">
              <star-rating
                :value="c.evaluation_stat.avg"
                :width="13"
                :height="13"
              ></star-rating>
            </div>
            <span class="c_tool_item_eval_count">
              ({{ c.evaluation_stat.count }})
            </span>
          </div>
          <div class="c_tool_item_eval c_tool_item_eval_count">
            <div>公開日:</div>
            <time class="mr-1">{{ c.from_field | formatDateHyphen }}</time>
            <div class="c_tool_item_eval" v-if="!c.to_indefinite">
              <div>期限:</div>
              <time class="mr-1">{{ c.to | formatDateHyphen }}</time>
            </div>
          </div>
        </div>
        <ul class="c_tool_item_tags">
          <li v-for="tag in c.tags" :key="tag.id">
            <a @click.stop="searchTag(tag.text)">{{ tag.text }}</a>
          </li>
        </ul>
      </div>
    </a>
    <!-- アクセス制限 -->
    <modal
      name="unauthorized"
      adaptive
      height="auto"
      width="85%"
      :maxWidth="640"
    >
      <section id="unauthorized" class="c_popup">
        <h2 class="c_popup_ttl">閲覧制限</h2>
        <p class="c_popup_lead">
          このコンテンツを閲覧する権限がありません。
          <br />閲覧制限を解除するには、所属の管理者経由で警察庁にご連絡ください。
        </p>
        <div class="c_popup_btns">
          <button
            class="c_btn"
            type="button"
            @click="$modal.hide('unauthorized')"
          >
            閉じる
          </button>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import StarRating from "@/components/StarRating";
import { jc3LearningDomain } from "@/../config.json";
export default {
  components: {
    StarRating,
  },
  props: {
    contents: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,
      page: 1,
      next: false,
      loading: false,
      search: "",
    };
  },
  methods: {
    clickContents(contents) {
      if (this.$auth.scopes.includes("readonly:npa")) {
        if (contents.category_name === "サイバー関連サービス等解説コンテンツ") {
          this.goContents(contents);
        } else {
          this.$modal.show("unauthorized");
        }
      } else {
        this.goContents(contents);
      }
    },
    goContents(contents) {
      if (contents.type === 1 || contents.type === 2) {
        // CTF or 教育
        let url = `https://${this.jc3LearningDomain}/events/${contents.id}`;
        window.open(url, "_blank");
      } else if (contents.type === 4) {
        // 外部サイト
        let url = contents.external_url;
        window.open(url, "_blank");
      } else if (contents.type === 8) {
        // 動画
        if (contents.category_name === "サイバー最新トレンド") {
          this.$router.push({
            name: "TrendSingle",
            params: { id: contents.id },
          });
        } else if (
          contents.category_name === "サイバー空間の脅威に立ち向かえ！"
        ) {
          this.$router.push({
            name: "FaceUpSingle",
            params: { id: contents.id },
          });
        } else if (
          contents.category_name === "サイバー関連サービス等解説コンテンツ"
        ) {
          this.$router.push({
            name: "BasicsSingle",
            params: { id: contents.id },
          });
        }
      } else if (contents.type === 16) {
        // ツール
        this.$router.push({ name: "ToolSingle", params: { id: contents.id } });
      }
    },
    searchTag(tag) {
      this.$router.push({ name: "Search", query: { tag: tag } });
    },
  },
};
</script>
